import React from "react"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import Section from "../components/Section"
import { Container } from "../components/Container"
import SEO from "../components/seo"
import { LinkUnderline } from "../components/NormalLink"

const Wrapper = styled.div`
  min-height: 70vh;
`

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <Wrapper>
      <Container paddingTop paddingBottom>
        <Section>
          <Grid container>
            <Grid item xs={12} md={6}>
              <h1>Page not found</h1>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <LinkUnderline to="/">Back to homepage</LinkUnderline>
            </Grid>
          </Grid>
        </Section>
      </Container>
    </Wrapper>
  </div>
)

export default NotFoundPage
